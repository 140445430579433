<template>
  <div class="container">
    <div class="mx-auto loginContent" v-show="!requestSent">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        >
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/login').catch(() => {})"
          >LOGIN</a
        >
        > FORGOT LOGIN
      </div>
      <div class="card text-center">
        <div class="card-body forgot-login-body">
          <h2 class="text-primary">Forgot Your Login Information?</h2>
          <p>
            Enter your username to retrieve your password or enter your email to
            retrieve any associated usernames.
          </p>
          <hr />
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.forgotLoginForm"
          />
          <ValidationObserver ref="forgotLoginForm">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 right-border"
                >
                  <TextInput
                    id="forgotEmail"
                    name="Email"
                    :rules="
                      `requiredOr:@forgotUsername${
                        forgotUsername !== '' ? '' : '|email'
                      }`
                    "
                    vid="forgotEmail"
                    placeholder="Enter your email"
                    v-model="forgotEmail"
                    :disabled="forgotUsername !== ''"
                    class="mx-4"
                  />
                  <div class="mt-3">
                    <button
                      type="submit"
                      class="btn btn-primary mr-2"
                      :disabled="forgotUsername !== '' || submitted"
                    >
                      RETRIEVE USERNAME
                    </button>
                    <a
                      type="button"
                      class="btn btn-secondary text-white"
                      @click="$router.push('/login').catch(() => {})"
                      >BACK TO LOGIN</a
                    >
                  </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                  <TextInput
                    id="forgotUsername"
                    name="Username"
                    rules="requiredOr:@forgotEmail"
                    vid="forgotUsername"
                    placeholder="Enter your username"
                    v-model="forgotUsername"
                    :disabled="forgotEmail !== ''"
                    class="mx-4"
                  />
                  <div class="mt-3">
                    <button
                      type="submit"
                      class="btn btn-primary mr-2"
                      :disabled="forgotEmail !== '' || submitted"
                    >
                      RETRIEVE PASSWORD
                    </button>
                    <a
                      type="button"
                      class="btn btn-secondary text-white"
                      @click="$router.push('/login').catch(() => {})"
                      >BACK TO LOGIN</a
                    >
                  </div>
                </div>
              </div>
            </form>
            <p class="mt-6">
              <span style="color: red;">*</span> Indicates a required field
            </p>
            <p class="text-muted mt-3">
              This site is protected by reCAPTCHA and the Google
              <a
                class="text-primary"
                href="https://policies.google.com/privacy"
                target="_blank"
                >Privacy Policy</a
              >
              and
              <a
                class="text-primary"
                href="https://policies.google.com/terms"
                target="_blank"
                >Terms of Service</a
              >
              apply.
            </p>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div class="mx-auto loginContent" v-show="requestSent">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href=""
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        >
        <a
          class="text-primary"
          href=""
          @click="$router.push('/login').catch(() => {})"
          >LOGIN</a
        >
        > REQUEST RECEIVED
      </div>
      <div class="card text-center mb-5 pb-5">
        <div class="card-body p-5 request-received-body">
          <h2 class="text-primary">Request Received</h2>
          <br />
          <h3>
            Check your email for further details on retrieving your password or
            associated usernames.
          </h3>
          <br />
          <h4>Not getting emails?</h4>
          <div class="text-left ml-7 pl-4">
            <ul>
              <li>
                Confirm that your email address/username was entered correctly
              </li>
              <li>Check your spam or junk folder</li>
            </ul>
          </div>
          <br />
          <h4>Click Resend Link to send a new link.</h4>
          <button
            type="button"
            class="btn btn-primary mr-1"
            @click="resetRequest"
          >
            BACK
          </button>
          <button type="button" class="btn btn-primary" @click="onSubmit">
            RESEND LINK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import ProfileService from "@/services/ProfileService.js";
import CaptchaService from "@/services/CaptchaService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert";

export default {
  name: "ForgotLogin",
  title: "Forgot Login",
  data() {
    return {
      forgotUsername: "",
      forgotEmail: "",
      requestSent: false,
      errors: [],
      submitted: false
    };
  },
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert
  },
  methods: {
    async onSubmit() {
      const username = this.forgotUsername;
      const email = this.forgotEmail;
      const tenantId = this.$store.getters["tenant/tenantId"];
      this.$refs.forgotLoginForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.forgotLoginForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.forgotLoginForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            this.submitted = true;
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha("login");
            const captchaService = new CaptchaService(tenantId);
            const verified = await captchaService.verifyCaptcha(token);
            if (verified && verified.success === true && verified.score > 0.6) {
              if (username !== "") {
                this.resetPass(username, tenantId);
              } else if (email !== "" && username === "") {
                this.resetEmail(email, tenantId);
              }
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Captcha was not verified",
                layer: "public"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "public"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    async resetPass(username, tenantId) {
      const profileService = new ProfileService(tenantId);
      const user = await profileService.forgotPassword(username);
      if (user && !user.verified) {
        this.$router
          .push("/account-activation/" + user.externalUserId)
          .catch(() => {});
      } else {
        this.requestSent = true;
        this.$store.commit("auth/setExternalUserId", user.externalUserId);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-info",
          message:
            "Password reset submitted. If an email is associated with this username, a link will be sent to complete the password reset process.",
          layer: "public"
        });
        this.requestReceived();
      }
    },
    async resetEmail(email, tenantId) {
      const profileService = new ProfileService(tenantId);
      await profileService.forgotUsername(email);
      this.requestSent = true;
      this.$store.commit("alert/setErrorAlert", {
        type: "alert-info",
        message:
          "Usernames associated with your email will be sent shortly. After username retrieval, use your existing password to log in.",
        layer: "public"
      });
      this.requestReceived();
    },
    resetRequest() {
      this.submitted = false;
      this.requestSent = false;
    },
    requestReceived() {
      this.$store.commit("alert/setErrorAlert", {
        type: "alert-info",
        message:
          "Your request was received, a notification/link was sent to your email address.",
        layer: "public"
      });
    }
  }
};
</script>

<style scoped>
.forgot-login-body {
  margin-bottom: 70px;
}
.request-received-body {
  margin-bottom: 80px;
}
h3 {
  font-size: large;
}
.right-border {
  border-right-style: solid;
  border-right-color: #e6e6e6;
}
</style>
